.dataTables_wrapper .dataTables_paginate a.paginate_button.current {
  background: #2b6448!important;
  color: white!important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: all 0.2s linear;
  font-size: 0.9rem;
  padding: .5rem .75rem;
  line-height: 1.25;
  border: 0;
  border-radius: 0.125rem;
}

.dataTables_wrapper .dataTables_paginate a.paginate_button.current:hover {
  color: white!important;
  background: #d68e00!important;
  transition: all 0.3s linear;
  border-radius: 0.125rem;
  border: #d68e00;
}

.data-table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 1%;
}

#tableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}



.toggle-vis {
  background: #2b6448!important;
  color: white!important;
  margin-right: .3rem;
  order: 1;
}

.toggle-vis:hover {
  color: white;
  opacity: .8;
}

.reset {
  background: #d68e00!important;
  border-color: #d68e00!important;
}

.dt-button embed {
  outline: none;
}

.table a {
  color: black;
}

.show-modal-button:hover {
  cursor: pointer;
}

.editModal:hover {
  cursor: pointer;
  text-decoration: underline!important;
}


div.dt-buttons {
  position: relative;
  float: left;
  width: 100%!important;


  &.buttons-right {
    float: right;
  }
}

#tableHeader {
  display: flex;
  align-items: flex-end;
}

.dataTables_length {
  font-size: 0.8rem;
  margin-top: 20px;
  width: 20%;
  display: inline-flex;
}

#data-table_filter {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.dataTables_filter {
  display: flex;
  align-items: center;
}

.dataTables_filter label {
  align-items: center;
  font-size: 0.8rem;
  order: 2;
  margin-bottom: .3rem;
  padding-left: 30px;
}

div.dt-button-collection {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 3px;
  padding: 8px 8px 4px 8px;
  border: 1px solid #ccc;
  border: 1px solid rgba( 0, 0, 0, 0.4 );
  background-color: white;
  overflow: hidden;
  z-index: 2002;
  border-radius: 5px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  max-width: 80%!important;
}

div.dt-button-collection .dt-button,
div.dt-button,
a.dt-button {
  position: relative;
  right: 0;
  width: 100%;
  color: black;
  background: white!important;
  border: none!important;
  box-shadow: none!important;
  display: block;
  float: none;
  margin-bottom: 4px;
  margin-right: 0;
}

div.dt-button-collection .dt-button:hover,
div.dt-button:hover,
a.dt-button:hover {
  opacity: .5;
}

div.dt-button-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 2001;
}

.dtsp-titleRow {
  display: block;
}

.dtsp-closePanes {
  float: right;
  margin: 20px;
  border: 1px solid transparent;
  background-color: transparent;
  padding: 10px;
  margin-bottom: 0px;
  margin-top: 5px;
}

.dtsp-closePanes:hover {
  background-color: #f0f0f0;
  border-radius: 2px;
}

@media screen and (max-width: 640px) {
  div.dt-buttons {
    float: none !important;
    text-align: center;
  }
  div.dt-button-collection {
    left: 0%!important;
    top: -65%!important;
    width: 100%;
  }

  .dtsp-titleRow {
    display: grid;
  }

  #data-table_filter {
    display: flex;
    flex-direction: column;
  }
}

select[name="data-table_length"] {
  margin-left: 0.4em;
  margin-right: 0.5em;
}

