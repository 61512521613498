div#async-search.rbt-menu.dropdown-menu.show {
  width: 22em !important;
}

 .rbt-menu a {
  color: #036545;
}

.rbt-menu .es-option-container:hover {
  background-color: #036545;
  color: #fff;
}

.rbt-menu .es-option-container:hover a {
  color: #d4a447;
}

.rbt-menu a > div {
  width: 100%;
}

.rbt-menu .dropdown-item.active, .rbt-menu .dropdown-item:hover {
  background-color: #036545;
}

.rbt-menu .dropdown-item {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0px;
}

.rbt-menu .dropdown-item:hover {
  color: white;
}

.rbt-menu .dropdown-item.active a, .rbt-menu .dropdown-item:hover a {
  color: #d4a447;
}