.fee-details-button {
  padding-left: 2px;
}

.fee-details-button:hover {
  cursor: pointer;
}

.ordered-column:hover {
  cursor: pointer;
}
