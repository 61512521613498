  .col, .col-8 {
    border-bottom: 1px solid #AAAAAA;
    padding: 4px 2px;
    font-size: 1em;
    font-weight: 600;
  }

  .info-row:nth-of-type(even) {
    background: #C5DACF;
  }

  .info-row div:nth-of-type(even) {
    font-weight: bold;
  }

  .info-section {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: -8px;
  }

  .internal-info {
    font-size: 1.2em;
    font-weight: 600;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: -3px;
  }

  .internal-info div {
    color: '#2b6448';
  }

  .lot-image {
    max-height: 220px;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-container:hover .overlay {
    background: rgba(0, 0, 0, .3);
  }

  .image-container:hover .add-image-text {
    opacity: 1;
  }

  .overlay {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease;
  }

  .add-image-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    color: white;
    transition: opacity 1s ease;
    font-weight: bold;
  }

  .add-first-image-container {
    transition: background 1s ease;
  }

  .add-first-image-container:hover {
    opacity: .7;
    background-color: #2b6448;
    color: white;
  }

.add-first-image-text {
   padding: 96px;
}
