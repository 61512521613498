.topcorner{
  position:absolute;
  top:10px;
  right: 10px;
 }

.zero {
  left: 0;
  line-height: 200px;
  margin-top: 200px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100%;
}
