/* these class names are coming in from React-Bootstrap */
.dropdown-item.active, .dropdown-item:active {
  background-color: #2b6448;
  color: white;
}

.navbar  .noBoxShadow{
  box-shadow: none;
}

@media (max-width: 992px) {
  .small{
    height: 100%;
  }
}

.small {
  height: 20px;
}
@media (max-width: 992px) {
  .small{
    height: 100%;
  }
}

@media (min-width: 992px) {
  .navbar-light .navbar-nav.xlf-yellow .nav-link::after {
    content: "                ";
    white-space: pre;
  }
  .navbar {
    padding-top:0px;
    padding-bottom:0px;
  }
}
