.modal-600 {
  width: 600px;
  max-width: 600px;
}

@media only screen and (max-width: 992px) {
  .modal-600 {
    width: 90%;
  }
}
